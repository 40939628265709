<template>
  <div class="app-main-content">
    <card title="报表" class="report-group">
      <div class="tab-item all" :class="{active: activeTab === ''}" @click="changeTabAll">
        <span class="text">所有表格</span>
        <span class="num"></span>
      </div>
      <div class="tab-action">
        分组
      </div>
      <div class="tab-item-list">
        <div
          class="tab-item"
          v-for="item in report_menu_list"
          :key="item.cat_code"
          :class="{active: activeTab === item.cat_code}"
          @click="changeTab(item)">
          <span class="text">{{item.cat_name}}</span>
        </div>
      </div>
    </card>
    <card class="report-list-card">
      <template #title>
        <span class="list-title">类型</span>
      </template>
      <div class="report-list">
        <a-table :data-source="report_form_type_list" rowKey="cat_code" :pagination="false" :customRow="(record) => customRow(record, activeTab)">
          <a-table-column data-index="cat_name" :title="currentMenuName">
            <template #default="t">
              {{t}}
            </template>
          </a-table-column>
        </a-table>
      </div>
    </card>
  </div>
</template>

<script>
import {fetch} from "@/utils/request";

export default {
  name: 'projectReport',
  data() {
    return {
      activeTab: "",
      groupList: [
        {
          cat_name: "所有报表",
          cat_code: "",
          list: []
        }
      ],
      catCountMap: new Map(),
      report_menu: [],
      report_form_type: [],
      mapRelation: {
        
      },
    }
  },
  computed: {
    currentMenuName() {
      if (this.activeTab !== "") {
        let find = this.report_menu.find(item => item.cat_code === this.activeTab)
        if (find) {
          return find.cat_name
        }
        return ""
      }
      return "全部"
    },
    report_menu_list() {
      return this.report_menu.filter(item => {
        return this.$getP().includes(item.temp10)
            // && item.cat_name !== '工时报表'
      })
    },
    report_form_type_list() {
      return this.report_form_type.filter(item => {
        return this.$getP().includes(item.temp10)
            // && !['14','15','16','17','18','19','20','21','22'].includes(item.cat_code)
      })
    }
  },
  created() {

  },
  mounted() {
    this.getCatList('report_menu', 1)
    this.getCatList('report_menu', 2)
  },
  methods: {
    getCatList(cat_type = 'report_menu', level = 1, routemap = "") {
      return fetch("post", "/cpy/cat/retrieve", {cat_type, level, routemap, page_size: 999}).then(res => {
        if (res.status === 0) {
          let data = res.data.datas
          if (level === 1) {
            this.report_menu = data
          } else {
            this.report_form_type = data
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    jumpReportMoney() {
      this.$router.push({
        path: "/project/report/money",
        query: {
          prj_oid: this.$route.query.prj_oid
        }
      })
    },
    customRow(record,report_menu_code) {
      return {
        on: {
          click: () => this.clickRow(report_menu_code, record)
        }
      }
    },
    clickRow(report_menu_code, record) {
      let {href} = this.$router.resolve({
        path: "/project/report/detail",
        query: {
          prj_oid: this.$route.query.prj_oid,
          cat_code: record.cat_code,
          active_tab: this.activeTab
        }
      })
      location.href = href
    },
    changeTab(item) {
      console.log(item);
      this.activeTab = item.cat_code
      this.getCatList(item.cat_type, item.level-0+1, !item.routemap ? '' : `${item.routemap}/${item.oid}`)
    },
    changeTabAll() {
      this.activeTab = ''
      this.getCatList('report_menu', 1)
      this.getCatList('report_menu', 2)
    }
  }
}
</script>

<style lang="less" scoped>
.app-main-content {
  display: flex;
  justify-content: space-between;
  .report-group {
    box-shadow: 0 2px 10px 0 rgba(182,182,182,0.50);
    width: 240px;
    flex-shrink: 0;
    .tab-item {
      padding: 0 20px;
      height: 38px;
      font-weight: 500;
      line-height: 38px;
      color: #4D4D4D;
      font-size: 14px;
      &.active {
        background-color: fadeout(@primary-color, 90%);
        color: var(--primary-color);
      }
      &.all {
        font-size: 16px;
      
        .num {
          font-size: 16px;
        }
      }
      .num {
        color: #A7A7A7;
        margin-left: 3px;
      }
      
    }
    .tab-item-list .tab-item {
        font-weight: 400;
      }
    .tab-action {
      padding: 0 20px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 46px;
      border-top: 1px solid #EDEDED;
      border-bottom: 1px solid #EDEDED;
      .btn {
        padding: 0 10px;
      }
    }
  }
  /deep/.edp-card-body {
    overflow: hidden;
  }
  .report-list-card {
    flex: 1;
    margin-left: 20px;
    box-shadow: 0 2px 10px 0 rgba(182,182,182,0.50);
    .report-list {
      width: 100%;
      height: 100%;
    }
    .list-title {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #3B3B3B;
      .num {
        color: #A7A7A7;
        margin-left: 5px;
      }
    }
  }
}
</style>